import React, { useRef, useState, useEffect } from "react";
import {
  GraphColumn,
  ValuesColumn,
  WheelWrapper,
  WrapperHint,
  ArrowDown,
  HintAction,
  HintDescription,
  ConfirmBtn,
  Icon,
  WrapperProgressMobile,
  ProgressNumbers,
  ProgressBar,
  WrapperBtnsMobile,
  SharedButton,
  ConfirmBtnMobile,
} from "./styles";
import { Polar } from "react-chartjs-2";
import Plugin from "chartjs-plugin-labels";
import getWPData from "../../API";
import Pdf from "react-to-pdf";
import downloadIcon from "../../assets/downloadIcon.png";
import sharedIcon from "../../assets/sharedIcon.png";
import htmlToImage from "html-to-image";
import LogoPNG from "../../assets/logoBee.png";

const settingsDefault = () => {
  const arr = [];
  for (let i = 0; i < 1; i++) {
    arr.push({ value: "", rate: 0 });
  }
  return arr;
};

const WheelGenerator = () => {
  const [progressNumber, setProgressNumber] = useState(0);
  const [userInput, setUserInput] = useState("");
  const [userRate, setUserRate] = useState();
  const [seletedValues, setSelectedValues] = useState([{ value: "", rate: 0 }]);
  const [isHint, setHint] = useState(false);
  const [isDone, setDone] = useState(false);
  const [errors, setErrors] = useState([]);
  const [hintContent, setHintContent] = useState(null);
  const queryString = require("query-string");
  const chart = useRef();
  const pdf = useRef();
  const pdfOptions = {
    orientation: "portrait",
    unit: "in",
    format: [4, 2],
  };

  useEffect(() => {
    const values = queryString.parse(window.location.search, {
      arrayFormat: "comma",
    });
    console.warn("Values", values);

    if (values && values.values && values.rates) {
      let data = [{ value: "", rate: 0 }];

      if (
        typeof values.values === "string" &&
        typeof values.rates === "string"
      ) {
        data = [{ value: values.values, rate: values.rates }];
      } else {
        data = values.values.map((x, i) => {
          return { value: x, rate: values.rates[i] };
        });
      }

      setSelectedValues(data);
    }
  }, []);

  useEffect(() => {
    getWPData().then((data) => setHintContent(data.hint[0]));
  }, []);

  const data = {
    datasets: [
      {
        data: seletedValues.map((el) => el.rate),

        backgroundColor: [
          "#a219a2",
          "#fe4774",
          "#40b4b4",
          "#194774",
          "#fe7419",
          "#fed019",
          "#2c9a2c",
          "#74fea2",
          "#1974a2",
          "#73a2fe",
        ],
      },
    ],
    labels: seletedValues.map((el) => el.value),
  };

  const options = {
    legend: {
      display: false,
    },
    // responsive: false,
    scale: {
      ticks: {
        min: 0,
        max: 10,
        stepSize: 1,
        display: false,
      },
    },
    animation: {
      animateScale: true,
      animateRotate: false,
      onComplete: function(animation) {
            handleImage();
        }
    },
    tooltips: {
      enabled: false,
    },
    plugins: {
      labels: {
        // font size, default is defaultFontSize
        fontSize: 14,
        // font color, can be color array for each data or function for dynamic color, default is defaultFontColor
        fontColor: "#000",
        // font style, default is defaultFontStyle
        fontStyle: "normal",
        // font family, default is defaultFontFamily
        fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        // draw text shadows under labels, default is false
        textShadow: true,
        // text shadow intensity, default is 6
        shadowBlur: 3,
        render: function (args) {
          // args will be something like:
          // { label: 'Label', value: 123, percentage: 50, index: 0, dataset: {...} }
          return `${args.label} ${args.value !== 0 ? args.value : ""}`;
          // return object if it is image
          // return { src: 'image.png', width: 16, height: 16 };
        },
        // position to draw label, available value is 'default', 'border' and 'outside'
        // bar chart ignores this
        // default is 'default'
        position: "outside",
        outsidePadding: 4,
        textMargin: 4,
      },
    },
  };

  const handleImage = () => {
    var node = chart.current;

    htmlToImage
      .toPng(node)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        document.querySelector(".pdf__image-wrapper").appendChild(img);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  return (
    <>
      <WheelWrapper>
        {/* <button onClick={handleImage}>Click</button> */}
        <GraphColumn pdf>
          <div ref={chart}>
            <Polar
              ref={chart}
              data={data}
              options={options}
              width={540}
              height={324}
              plugins={[Plugin]}
            />
          </div>
          {/*<WrapperBtnsMobile isDone={isDone}>*/}
          {/*  <WrapperProgressMobile>*/}
          {/*    <ProgressNumbers>{progressNumber} / 10</ProgressNumbers>*/}
          {/*    <ProgressBar progressNumber={progressNumber} />*/}
          {/*  </WrapperProgressMobile>*/}
          {/*  {!isDone && (*/}
          {/*    <ConfirmBtnMobile onClick={() => setDone(true)}>*/}
          {/*      I'm done*/}
          {/*    </ConfirmBtnMobile>*/}
          {/*  )}*/}
          {/*</WrapperBtnsMobile>*/}
          <WrapperHint isHint={isHint}>
            <HintAction onClick={() => setHint(!isHint)}>
              {hintContent && hintContent.hint_title} <ArrowDown />
            </HintAction>
            {isHint && (
              <HintDescription>
                {hintContent && hintContent.description}
              </HintDescription>
            )}
          </WrapperHint>
          <Pdf targetRef={pdf} filename="life-wheel.pdf" x={26} y={20}>
            {({ toPdf }) => (
              <SharedButton onClick={toPdf}>
                <Icon src={downloadIcon} />
                Download PDF
              </SharedButton>
            )}
          </Pdf>
        </GraphColumn>
      </WheelWrapper>
      <article
        style={{
          height: "1000px",
          backgroundColor: "#f4f4f4",
          position: "fixed",
          left: "-2600px",
          top: 0,
          width: "600px",
        }}
        id="pdf"
        ref={pdf}
      >
        <header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "black",
            padding: "30px",
            width: "600px",
          }}
        >
          <img src={LogoPNG}></img>
          <p style={{ color: "white", fontSize: "8px" }}>
            Providing a clear way forward with emotional intelligence
          </p>
        </header>
        <div
          style={{
            display: "flex",
            height: "800px",
            alignItems: "center",
            justifyContent: "center",
          }}
          class="pdf__image-wrapper"
        ></div>
        <footer
          style={{
            position: "relative",
            display: "flex",
            padding: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "80%",
              backgroundColor: "black",
              height: "1px",
              position: "absolute",
              marginTop: "5px",
            }}
          ></div>
          <a
            style={{
              display: "block",
              backgroundColor: "#f4f4f4",
              padding: "5px",
              color: "black",
              textDecoration: "none",
              position: "relative"
            }}
            href="www.beealigned.com.au"
          >
            www.beealigned.com.au
          </a>
        </footer>
      </article>
    </>
  );
};

export default WheelGenerator;
