import { Link } from "react-router-dom";
import styled, { keyframes, css } from "styled-components";
import Bg from "../../../assets/bg.png";
import Circle from "../../../assets/circle.png";

export const WrapperView = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  background-color: #eede2c;
  display: flex;
  flex-direction: column;
`;

export const SideView = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  background-color: #eede2c;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`;

export const SideViewTitle = styled.h3`
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  margin: 16px 0;
`;

export const SideViewText = styled.p`
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
`;

export const SideViewButton = styled(Link)`
  width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20%;
  cursor: pointer;
  text-decoration: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-top: auto;
  margin-bottom: auto;
`;

export const SideViewButtonIcon = styled.img`
  width: 22%;
  padding: 6px;
  margin-right: 1rem;
  max-width: 40px;
`;

export const SideViewButtonText = styled.p`
  color: #eede2c;
  font-size: 14px;
  font-weight: 700;
  width: 78%;
  text-align: center;
  border-left: 1px solid gray;
`;

export const WrapperHome = styled.div`
  display: flex;
  width: 100%;
  background-image: url(${Bg});
  background-size: cover;
  height: 200px;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
`;

export const WelcomeText = styled.p`
  text-shadow: 0 3px 12px rgba(0, 0, 0, 0.32);
  color: #eede2c;
  font-size: 28px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  padding-bottom: 2rem;
`;

export const CallBox = styled.div`
  background-color: #eede2c;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export const CallText = styled.p`
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
`;
