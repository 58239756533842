import React, { useEffect, useState } from "react";
import {
  CallBox,
  CallButton,
  CallText,
  SideView,
  SideViewButton,
  SideViewButtonIcon,
  SideViewButtonText,
  SideViewText,
  SideViewTitle,
  WelcomeText,
  WrapperHome,
  WrapperView,
  SideViewCircle,
} from "./styles";
import BtnIcon from "../../assets/buttonIcon.png";
import HomeMobile from "./HomeMobile";
import getWPData from "../../API";
import Bg from "../../assets/bg.png";

const isMobile = window.innerWidth < 1024;

const Home = ({ content, infoContent, background }) => {
  const [isSideView, setSideView] = useState(false);

  return (
    <WrapperView>
      {isSideView && (
        <SideView>
          <SideViewTitle>{infoContent.headline}</SideViewTitle>
          <SideViewText>{infoContent.description}</SideViewText>
          <SideViewButton to="/wheels">
            <SideViewButtonIcon src={BtnIcon} />
            <SideViewButtonText>{infoContent.button}</SideViewButtonText>
          </SideViewButton>
        </SideView>
      )}
      <WrapperHome style={{backgroundImage: `url(${background ? background : Bg})`}} isSideView={isSideView}>
        {isSideView && <SideViewCircle />}
        <WelcomeText>{content.headline}</WelcomeText>
        <CallBox>
          <CallText>{content.description}</CallText>
          <CallButton onClick={() => setSideView(true)}>
            {content.button}
          </CallButton>
        </CallBox>
      </WrapperHome>
    </WrapperView>
  );
};

const RenderHome = () => {
  const [content, setContent] = useState(null);
  const [infoContent, setInfoContent] = useState(null);
  const [bgr, setBgr] = useState(null);
  useEffect(() => {
    getWPData().then((data) => {
      setContent(data.landing_screen[0]);
      setInfoContent(data.info_screen[0]);
      setBgr(data.background)
    });
  }, []);

  if (content && infoContent) {
    if (isMobile)
      return <HomeMobile background={bgr} content={content} infoContent={infoContent} />;
    return <Home background={bgr} content={content} infoContent={infoContent} />;
  }
  return null;
};

export default RenderHome;
