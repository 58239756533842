import React, { useState, useEffect } from "react";
import {
  CallBox,
  CallText,
  SideView,
  SideViewButton,
  SideViewButtonIcon,
  SideViewButtonText,
  SideViewText,
  SideViewTitle,
  WelcomeText,
  WrapperHome,
  WrapperView,
} from "./styles/mobile";
import BtnIcon from "../../assets/buttonIcon.png";
import getWPData from "../../API";
import Bg from "../../assets/bg.png";

const HomeMobile = ({ content, infoContent }) => {
  const [bgr, setBgr] = useState(null);
  useEffect(() => {
    getWPData().then((data) => {
      setBgr(data.background ? data.background : Bg);
    });
  }, []);
  return (
    <WrapperView>
      <WrapperHome style={{backgroundImage: `url(${bgr})`}}>
        <WelcomeText>{content.headline}</WelcomeText>
      </WrapperHome>
      <CallBox>
        <CallText>{content.description}</CallText>
      </CallBox>
      <SideView>
        <SideViewTitle>{infoContent.headline}</SideViewTitle>
        <SideViewText>{infoContent.description}</SideViewText>
      </SideView>
      <SideViewButton to="/wheels">
        <SideViewButtonIcon src={BtnIcon} />
        <SideViewButtonText>{infoContent.button}</SideViewButtonText>
      </SideViewButton>
    </WrapperView>
  );
};

export default HomeMobile;
