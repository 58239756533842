import React from "react";
import Home from "./components/Home/Home";
import Header from "./components/_shared/Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Wheel from "./components/Wheel/Wheel";
import Thanks from "./components/Thanks/Thanks";
import WheelGenerator from "./components/WheelGenerator/WheelGenerator";

function App() {
  return (
    <div className="App">
      <Router basename="/Beealigned">
        <Header />
        <Switch>
          <Route path="/thanks">
            <Thanks />
          </Route>
          <Route path="/wheels">
            <Wheel />
          </Route>
          <Route path="/pdf">
            <WheelGenerator />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
