const getWPData = async () => {
  const requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Cache-Control": "no-cache",
    },
    body: new URLSearchParams({
      action: "api",
    }),
  };

  const request = await fetch(
    `${process.env.REACT_APP_API}/wp/wp-admin/admin-ajax.php`,
    requestOptions
  );

  const json = await request.json();
  return json.data.fields;
};

export default getWPData;
