import React, { useState, useEffect, useRef } from "react";
import {
  ValuesTitle,
  ValuesDescription,
  ValuesBox,
  InputGroup,
  InputText,
  InputField,
  SharedBtns,
  SendButton,
  SharedButton,
  Icon,
  ErrorsWrapper,
} from "./styles";
import downloadIcon from "../../assets/downloadIcon.png";
import sharedIcon from "../../assets/sharedIcon.png";
import getWPData from "../../API";
import { useHistory } from "react-router-dom";
import Pdf from "react-to-pdf";
import { FacebookProvider, ShareButton } from "react-facebook";
import _reduce from "lodash/reduce";
import htmlToImage from "html-to-image";
import LogoPNG from "../../assets/logoBee.png";

const ValuesConfirmation = ({ selectedValues, chartRef, img }) => {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [content, setContent] = useState(null);
  const [errors, setErrors] = useState([]);
  const history = useHistory();
  const pdf = useRef();

  console.log(
    "Data",
    chartRef.current,
    chartRef.current,
    chartRef.current.outerHTML
  );

  useEffect(() => {
    getWPData().then((data) => setContent(data.congratulations[0]));
  }, []);

  const onSendClick = () => {
    const isEmailValid = userEmail.length > 3 && userEmail.includes("@");
    const isNameValid = userName.length > 3;
    const arr = [];
    if (!isEmailValid) {
      arr.push("Insert correct email address");
    }
    if (!isNameValid) {
      arr.push("Name is required");
    }
    setErrors(arr);
    if (isNameValid && isEmailValid) {
      (async () => {
        try {
          var requestOptions = {
            method: "POST",
            redirect: "follow",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Cache-Control": "no-cache",
            },
            body: new URLSearchParams({
              action: "sendEmail", //Required parameter
              email: userEmail,
              message: JSON.stringify({ data: selectedValues }),
              name: userName,
            }),
          };

          const request = await fetch(
            `${process.env.REACT_APP_API}/wp/wp-admin/admin-ajax.php`, //Always same url | action parameter is and endpoint
            requestOptions
          );

          const json = await request.text();
          console.warn(json);
          history.push("/thanks");
        } catch (error) {
          console.warn("eeee", error);
          alert("Error sending email");
        }
      })();
    }
  };

  if (content) {
    return (
      <>
        <ValuesTitle>{content.headline}</ValuesTitle>
        <ValuesDescription fullWidth>{content.description}</ValuesDescription>
        <ValuesBox withShared>
          <InputGroup width="100%">
            <InputText margin>Name</InputText>
            <InputField
              value={userName}
              type="text"
              onChange={(e) => setUserName(e.target.value)}
            />
          </InputGroup>
          <InputGroup width="100%">
            <InputText marginFull>Email</InputText>
            <InputField
              type="email"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
          </InputGroup>
          <ErrorsWrapper>
            {errors.map((error) => (
              <p>{error}</p>
            ))}
          </ErrorsWrapper>
          <SendButton onClick={onSendClick}>{content.button}</SendButton>
          <InputText>{content.button_description}</InputText>
          <SharedBtns>
            <Pdf targetRef={pdf} filename="life-wheel.pdf" x={26} y={20}>
              {({ toPdf }) => (
                <SharedButton onClick={toPdf}>
                  <Icon src={downloadIcon} />
                  Download PDF
                </SharedButton>
              )}
            </Pdf>
            <FacebookProvider appId={"352990962656570"}>
              <ShareButton
                className={"buttonShare"}
                href={`${process.env.REACT_APP_SITE}/pdf?values=${selectedValues
                  .map((e) => e.value)
                  .join(",")}&rates=${selectedValues
                  .map((e) => e.rate)
                  .join(",")}`}
              >
                <Icon src={sharedIcon} />
                Share on Facebook
              </ShareButton>
            </FacebookProvider>
          </SharedBtns>
        </ValuesBox>

        <article
          style={{
            height: "1000px",
            backgroundColor: "#f4f4f4",
            position: "fixed",
            left: "-1600px",
            top: 0,
            width: "600px",
          }}
          id="pdf"
          ref={pdf}
        >
          <header
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "black",
              padding: "30px",
              width: "600px",
            }}
          >
            <img src={LogoPNG}></img>
            <p style={{ color: "white", fontSize: "8px" }}>
              Providing a clear way forward with emotional intelligence
            </p>
          </header>
          <div
            style={{
              display: "flex",
              height: "800px",
              alignItems: "center",
              justifyContent: "center",
            }}
            class="pdf__image-wrapper"
          >
              <img src={img} />
          </div>
          <footer
            style={{
              position: "relative",
              display: "flex",
              padding: "10px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "80%",
                backgroundColor: "black",
                height: "1px",
                position: "absolute",
                marginTop: "5px",
              }}
            ></div>
            <a
              style={{
                display: "block",
                backgroundColor: "#f4f4f4",
                padding: "5px",
                color: "black",
                textDecoration: "none",
                position: "relative",
              }}
              href="www.beealigned.com.au"
            >
              www.beealigned.com.au
            </a>
          </footer>
        </article>
      </>
    );
  }
  return null;
};

export default ValuesConfirmation;
