import { Link } from "react-router-dom";
import styled from "styled-components";

export const WheelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f4f4f4;
  height: calc(100% - 67px);
  min-height: 800px;
  width: 100%;
  padding: 21px 120px;
  @media (max-width: 1024px) {
    padding: 10px;
    flex-direction: column;
    height: auto;
  }
`;

export const ValuesColumn = styled.div`
  width: 50%;

  @media(max-width: 1599px) {
    height: 100%;
  }
  @media (max-width: 1024px) {
    width: 100%;
    height: auto;
  }
`;

export const ValuesTitle = styled.h3`
  color: #000000;
  font-size: 36px;
  font-weight: 700;
`;

export const ValuesDescription = styled.p`
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin: 20px 0;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "70%")};
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const ValuesBox = styled.div`
  box-shadow: 0 3px 32px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  background-color: rgba(253, 253, 253, 0.73);
  padding: 21px 15px 37px 15px;
  position: relative;
  padding-bottom: ${({ withShared }) => withShared && "100px"};
`;

export const ValuesFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${({ width }) => width};
`;

export const InputText = styled.p`
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  margin: ${({ marginFull }) => marginFull && "12px 0"};

  margin-bottom: ${({ margin }) => margin && "12px"};
`;

export const SelectionGroup = styled.div``;

export const SelectionButton = styled.button`
  border-radius: 32px;
  border: 1px solid #dbdbdb;
  padding: 9px 18px;
  margin: 0 5px 5px 0;
  background: ${({ isActive }) => (isActive ? "#eede2c" : "transparent")};
  outline: none;
  cursor: pointer;

  &:hover {
    background: #dbdbdb;
  }
`;

export const InputField = styled.input`
  border-radius: 2px;
  border: 1px solid #dbdbdb;
  background-color: #ffffff;
  opacity: 0.73;
  padding: 0.5rem;
  font-size: 1.5rem;
  width: 100%;
`;

export const WrapperBtns = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;
`;

export const AddBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background-color: #000000;
  cursor: pointer;
  color: #eede2c;
  padding: 5px 15px;
  margin-right: 10px;
`;

export const SignAdd = styled.p`
  font-weight: 700;
  font-size: 2rem;
  transform: translateY(-2px);
  padding-right: 10px;
  height: 100%;
`;

export const TextAdd = styled.p`
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  border-left: 1px solid gray;
  height: 100%;
  padding-left: 10px;
`;

export const DeleteBtn = styled.div`
  width: 48px;
  height: 48px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background-color: #c3c3c3;
  cursor: pointer;
  color: #eede2c;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SignDelete = styled.div`
  width: 16px;
  height: 4px;
  background-color: #eede2c;
`;

export const WrapperProgress = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1024px) {
    display: none;
  }
`;
export const WrapperProgressMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1024px) {
    display: none;
  }
`;

export const WrapperBtnsMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 2rem 0;
  justify-content: ${({ isDone }) => isDone && "center"};

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const ProgressNumbers = styled.p`
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

export const ProgressBar = styled.div`
  width: 160px;
  height: 8px;
  border-radius: 9px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  position: relative;

  &:after {
    content: "";
    display: block;
    height: 100%;
    background-color: #8d8d8d;
    width: ${({ progressNumber }) => `${progressNumber * 10}%`};
    top: 0;
    left: 0;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    border-top-right-radius: ${({ progressNumber }) =>
      progressNumber === 10 && "9px"};
    border-bottom-right-radius: ${({ progressNumber }) =>
      progressNumber === 10 && "9px"};
  }
`;

export const GraphColumn = styled.div`
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (min-width: 1600px) {
    height: 70%;
  }
  @media (max-width: 1024px) {
    width: 100%;
    height: auto;
    padding-top: 2rem;
  }
`;

export const WrapperHint = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  padding: 1rem;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  width: 450px;
  background: ${({ isHint }) => isHint && "#fff"};
  position: absolute;
  top: 0;
  @media (max-width: 1024px) {
    position: relative;
    width: 100%;
    margin: 1rem 0 2rem 0;
  }
`;

export const HintAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const HintDescription = styled.p`
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin: 1rem 0 0.5rem 0;
`;

export const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #7e7e7e;
`;

export const WrapperGraph = styled.div`
  width: 100%;
  height: 50%;
`;

export const ConfirmBtn = styled.button`
  width: 121px;
  height: 48px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #000000;
  border: none;
  border-radius: 8px;
  color: #eede2c;
  cursor: pointer;
  font-weight: 500;
  position: absolute;
  right: 0;
  bottom: 4rem;
  @media (max-width: 1024px) {
    display: none;
  }
`;
export const ConfirmBtnMobile = styled.button`
  width: 121px;
  height: 48px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #000000;
  border: none;
  border-radius: 8px;
  color: #eede2c;
  cursor: pointer;
  font-weight: 500;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const SendButton = styled.button`
  padding: 0.8rem 1rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #000000;
  border: none;
  border-radius: 8px;
  color: #eede2c;
  cursor: pointer;
  font-weight: 500;
  margin: 1rem 0;
  text-decoration: none;
  display: block;
  width: 30%;
  text-align: center;
  @media (max-width: 1024px) {
    width: 50%;
  }
`;

export const SharedBtns = styled.div`
  width: 100%;
  height: 80px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #eede2c;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 21px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SharedButton = styled.button`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  width: 50%;
  cursor: pointer;
  outline: none;

  &:nth-of-type(1) {
    border-right: 1px solid gray;
  }
`;

export const Icon = styled.img`
  /* padding-right: 1rem; */
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ErrorsWrapper = styled.div`
  margin-top: 1rem;

  p {
    color: red;
  }
`;
