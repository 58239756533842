import React, { useState, useEffect } from "react";
import styled from "styled-components";
import getWPData from "../../API";

const WrapperThanks = styled.div`
  height: calc(100vh - 67px);
  width: 100%;
  background: #eede2c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Title = styled.h3`
  color: #000000;
  font-size: 42px;
  font-weight: 700;
`;

const Text = styled.p`
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin: 1.5rem 0;
  width: 35%;
  @media (max-width: 1024px) {
    width: 90%;
  }
`;

const Button = styled.a`
  padding: 1rem 1rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #000000;
  border: none;
  border-radius: 8px;
  color: #eede2c;
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
`;

const Thanks = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    getWPData().then((data) => setContent(data.thanks_screen[0]));
  }, []);
  return (
    <>
      {content && (
        <WrapperThanks>
          <Title>{content.headline}</Title>
          <Text>{content.description}</Text>
          <Button href={content.button_link}>{content.button}</Button>
        </WrapperThanks>
      )}
    </>
  );
};

export default Thanks;
