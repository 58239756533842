import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import LogoPNG from "../../assets/logoBee.png";

const WrapperHeader = styled.header`
  background: #000000;
  padding: 5px 10%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

const Logo = styled.img``;

const Text = styled.p`
  color: #f4f4f4;
  font-size: 14px;
  font-weight: 400;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const Header = () => {
  return (
    <WrapperHeader>
      <Link to="/">
        <Logo src={LogoPNG} />
      </Link>
      <Text>Providing a clear way forward with emotional intelligence</Text>
    </WrapperHeader>
  );
};

export default Header;
