import React, { useState, useEffect } from "react";
import {
  ValuesTitle,
  ValuesDescription,
  ValuesBox,
  ValuesFlex,
  InputGroup,
  InputText,
  InputField,
  SelectionButton,
  SelectionGroup,
  WrapperBtns,
  AddBtn,
  SignAdd,
  TextAdd,
  DeleteBtn,
  SignDelete,
  WrapperProgress,
  ProgressNumbers,
  ProgressBar,
  ErrorsWrapper,
} from "./styles";
import getWPData from "../../API";

const ValuesForm = ({
  progressNumber,
  setProgressNumber,
  userInput,
  setUserInput,
  userRate,
  onInputTextChange,
  onInputNumberChange,
  onAddClick,
  onDeleteClick,
  errors,
}) => {
  const [content, setContent] = useState(null);
  const [inputContent, setInputContent] = useState(null);
  

  useEffect(() => {
    getWPData().then((data) => setInputContent(data.input_form[0]));
    getWPData().then((data) => setContent(data.wheel_screen[0]));
  }, []);

  if (content && inputContent) {
    return (
      <>
        <ValuesTitle>{content.headline}</ValuesTitle>
        <ValuesDescription>{content.description}</ValuesDescription>
        <ValuesBox>
          <ValuesFlex>
            <InputGroup width="65%">
              <InputText margin>{inputContent.type_value_create}</InputText>
              <InputField
                value={userInput}
                type="text"
                onChange={onInputTextChange}
              />
            </InputGroup>
            <InputGroup width="33%">
              <InputText margin>Rate 1-10</InputText>
              <InputField
                type="number"
                min="1"
                max="10"
                value={userRate}
                onChange={onInputNumberChange}
              />
            </InputGroup>
          </ValuesFlex>
          <ValuesFlex>
            <InputText>{inputContent.type_value_suggestions}</InputText>
          </ValuesFlex>
          <SelectionGroup>
            {inputContent.input_list.map(({ input_item }) => (
              <SelectionButton
                isActive={input_item === userInput}
                onClick={() => setUserInput(input_item)}
              >
                {input_item}
              </SelectionButton>
            ))}
          </SelectionGroup>
          <ErrorsWrapper>
            {errors.map((error) => (
              <p>{error}</p>
            ))}
          </ErrorsWrapper>

          <WrapperBtns>
            <AddBtn onClick={onAddClick}>
              <SignAdd>+</SignAdd>
              <TextAdd>Add</TextAdd>
            </AddBtn>
            <DeleteBtn onClick={onDeleteClick}>
              <SignDelete />
            </DeleteBtn>
          </WrapperBtns>
        </ValuesBox>
        <WrapperProgress>
          <ProgressNumbers>{progressNumber} / 10</ProgressNumbers>
          <ProgressBar progressNumber={progressNumber} />
        </WrapperProgress>
      </>
    );
  }

  return null;
};

export default ValuesForm;
