import React, { useRef, useState, useEffect } from "react";
import {
  GraphColumn,
  ValuesColumn,
  WheelWrapper,
  WrapperHint,
  ArrowDown,
  HintAction,
  HintDescription,
  ConfirmBtn,
  WrapperProgressMobile,
  ProgressNumbers,
  ProgressBar,
  WrapperBtnsMobile,
  ConfirmBtnMobile,
} from "./styles";
import { Polar } from "react-chartjs-2";
import Plugin from "chartjs-plugin-labels";
import ValuesForm from "./ValuesForm";
import ValuesConfirmation from "./ValuesConfirmation";
import getWPData from "../../API";
import htmlToImage from 'html-to-image';

const settingsDefault = () => {
  const arr = [];
  for (let i = 0; i < 1; i++) {
    arr.push({ value: "", rate: 0 });
  }
  return arr;
};

const Wheel = () => {
  const [progressNumber, setProgressNumber] = useState(0);
  const [userInput, setUserInput] = useState("");
  const [userRate, setUserRate] = useState();
  const [seletedValues, setSelectedValues] = useState([{ value: "", rate: 0 }]);
  const [isHint, setHint] = useState(false);
  const [isDone, setDone] = useState(false);
  const [errors, setErrors] = useState([]);
  const [hintContent, setHintContent] = useState(null);
  const [img, setImg] = useState(null);
  const chart = useRef();

  useEffect(() => {
    getWPData().then((data) => setHintContent(data.hint[0]));
  }, []);

  const onAddClick = () => {
    let arrErrors = [];

    if (progressNumber < 10 && userInput.length > 0 && userRate) {
      let isExist = false;
      seletedValues.forEach(({ value }) => {
        if (value === userInput) {
          isExist = true;
        }
      });
      if (!isExist) {
        setProgressNumber(progressNumber + 1);

        const arr = seletedValues;
        arr[progressNumber] = { value: userInput, rate: userRate };
        setSelectedValues(arr);
      } else {
        arrErrors.push("Value already exists");
      }
    }

    if (progressNumber === 10) {
      arrErrors.push("Maximum values exceed");
    }
    if (userInput.length === 0) {
      arrErrors.push("Value not selected");
    }
    if (!userRate) {
      arrErrors.push("Value not rated");
    }
    setErrors(arrErrors);
  };

  const onDeleteClick = () => {
    if (progressNumber > 0) {
      setProgressNumber(progressNumber - 1);
      const arr = seletedValues;
      arr[progressNumber - 1] = { value: "", rate: 0 };
      setSelectedValues(arr);
    }
  };

  const onInputTextChange = (e) => {
    setUserInput(e.target.value);
    e.target.style.background = "#eede2c";
    if (e.target.value === "") {
      e.target.style.background = "#fff";
    }
  };

  const onInputNumberChange = (e) => {
    if (+e.target.value >= 0 && +e.target.value <= 10) {
      setUserRate(e.target.value);
    }
  };
  const data = {
    datasets: [
      {
        data: seletedValues.map((el) => el.rate),

        backgroundColor: [
          "#a219a2",
          "#fe4774",
          "#40b4b4",
          "#194774",
          "#fe7419",
          "#fed019",
          "#2c9a2c",
          "#74fea2",
          "#1974a2",
          "#73a2fe",
        ],
      },
    ],
    labels: seletedValues.map((el) => el.value),
  };

  const options = {
    legend: {
      display: false,
    },
    // responsive: false,
    scale: {
      ticks: {
        min: 0,
        max: 10,
        stepSize: 1,
        display: false,
      },
    },
    animation: {
      animateScale: true,
      animateRotate: false,
          onComplete: function(animation) {
            handleImage();
        }
    },
    tooltips: {
      enabled: false,
    },
    plugins: {
      labels: {
        // font size, default is defaultFontSize
        fontSize: 14,
        // font color, can be color array for each data or function for dynamic color, default is defaultFontColor
        fontColor: "#000",
        // font style, default is defaultFontStyle
        fontStyle: "normal",
        // font family, default is defaultFontFamily
        fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
        // draw text shadows under labels, default is false
        textShadow: true,
        // text shadow intensity, default is 6
        shadowBlur: 3,
        render: function (args) {
          // args will be something like:
          // { label: 'Label', value: 123, percentage: 50, index: 0, dataset: {...} }
          return `${args.label} ${args.value !== 0 ? args.value : ""}`;
          // return object if it is image
          // return { src: 'image.png', width: 16, height: 16 };
        },
        // position to draw label, available value is 'default', 'border' and 'outside'
        // bar chart ignores this
        // default is 'default'
        position: "outside",
        outsidePadding: 4,
        textMargin: 4,
      },
    },
  };

  const handleImage = () => {
    var node = chart.current;

    htmlToImage
      .toPng(node)
      .then(function (dataUrl) {
        setImg(dataUrl);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  return (
    <WheelWrapper>
      <ValuesColumn>
        {isDone ? (
          <ValuesConfirmation selectedValues={seletedValues} chartRef={chart} img={img} />
        ) : (
          <ValuesForm
            progressNumber={progressNumber}
            setProgressNumber={setProgressNumber}
            userInput={userInput}
            setUserInput={setUserInput}
            userRate={userRate}
            onInputTextChange={onInputTextChange}
            onInputNumberChange={onInputNumberChange}
            onAddClick={onAddClick}
            onDeleteClick={onDeleteClick}
            errors={errors}
          />
        )}
      </ValuesColumn>
      <GraphColumn>
        <div ref={chart}>
          <Polar
            ref={chart}
            data={data}
            options={options}
            width={540}
            height={324}
            plugins={[Plugin]}
          />
        </div>

        {!isDone && (
          <ConfirmBtn onClick={() => setDone(true)}>I'm done</ConfirmBtn>
        )}
        <WrapperBtnsMobile isDone={isDone}>
          <WrapperProgressMobile>
            <ProgressNumbers>{progressNumber} / 10</ProgressNumbers>
            <ProgressBar progressNumber={progressNumber} />
          </WrapperProgressMobile>
          {!isDone && (
            <ConfirmBtnMobile onClick={() => setDone(true)}>
              I'm done
            </ConfirmBtnMobile>
          )}
        </WrapperBtnsMobile>
        <WrapperHint isHint={isHint}>
          <HintAction onClick={() => setHint(!isHint)}>
            {hintContent && hintContent.hint_title} <ArrowDown />
          </HintAction>
          {isHint && (
            <HintDescription>
              {hintContent && hintContent.description}
            </HintDescription>
          )}
        </WrapperHint>
      </GraphColumn>
    </WheelWrapper>
  );
};

export default Wheel;
