import { Link } from "react-router-dom";
import styled, { keyframes, css } from "styled-components";
import Bg from "../../../assets/bg.png";
import Circle from "../../../assets/circle.png";

const SlideIn = keyframes`
  0%{
    transform: translateX(-50vw);
  }
  100% {
    transform: translateX(0);
  }
`;

const EnterIn = keyframes`
  0%{
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const WrapperView = styled.div`
  width: 150vw;
  display: flex;
  align-items: center;
  height: calc(100% - 67px);
`;

export const SideView = styled.div`
  position: relative;
  height: 100%;
  width: 50vw;
  background-color: #eede2c;
  transform: translateX(-50vw);
  animation: ${SlideIn} 0.7s 0.5s forwards;
  padding: 120px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SideViewCircle = styled.div`
  width: 430px;
  height: 430px;
  position: absolute;
  top: 50%;
  z-index: 0;
  left: 0;
  opacity: 0;
  background-image: url(${Circle});
  background-size: cover;
  transform: translate(-50%, -50%);
  animation: ${EnterIn} 0.7s 0.5s forwards;
`;

export const SideViewTitle = styled.h3`
  color: #000000;
  font-size: 36px;
  font-weight: 700;
`;

export const SideViewText = styled.p`
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 2rem 0;
`;

export const SideViewButton = styled(Link)`
  width: 176px;
  height: 48px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  cursor: pointer;
  text-decoration: none;
`;

export const SideViewButtonIcon = styled.img`
  width: 22%;
  border-right: 1px solid gray;
  padding: 6px;
  max-width: 40px;
`;

export const SideViewButtonText = styled.p`
  color: #eede2c;
  font-size: 14px;
  font-weight: 700;
  width: 78%;
  text-align: center;
`;

const animationInject = css`
  transform: translateX(-50vw);
  animation: ${SlideIn} 0.7s 0.5s forwards;
`;

export const WrapperHome = styled.div`
  display: flex;
  width: 100vw;
  background-image: url(${Bg});
  background-size: cover;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${({ isSideView }) => isSideView && animationInject}
`;

export const WelcomeText = styled.p`
  text-shadow: 0 3px 12px rgba(0, 0, 0, 0.32);
  color: #eede2c;
  font-size: 42px;
  font-weight: 700;
  margin-top: 50px;
`;

export const CallBox = styled.div`
  width: 588px;
  height: 272px;
  box-shadow: 0 3px 32px rgba(0, 0, 0, 0.32);
  background-color: #eede2c;
  opacity: 0.9;
  padding: 30px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export const CallText = styled.p`
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const CallButton = styled.button`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: #000000;
  color: #eede2c;
  font-size: 14px;
  font-weight: 700;
  padding: 15px 24px;
  cursor: pointer;
  text-decoration: none;
`;
